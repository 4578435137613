<template>
  <div class="pa-5 pt-0">
    <v-calendar
      color="primary"
      :events="formattedEvents"
      event-start="startAt"
      event-end="endAt"
      type="month"
      :event-height="0"
      :start="start"
      :end="end"
      @click:date="redirectToAppointments"
    >
      <template #day="{ date }">
        <div class="d-flex justify-center align-center">
          <v-icon
            v-show="hasAppointmentsDate(date)"
            color="primary"
            class="mt-2"
          >
            event_available
          </v-icon>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  currentDateString,
  dateToString,
  dateToStringWithoutTime,
} from "@/utils/date";

export default {
  name: "SimplifiedAppointmentsCalendar",
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formattedEvents() {
      return this.events.map((event) => {
        const { startAt, endAt, patientName, ...rest } = event;
        return {
          startAt: dateToString(startAt, "YYYY-MM-DD HH:mm"),
          endAt: dateToString(endAt, "YYYY-MM-DD HH:mm"),
          name: patientName || "",
          ...rest,
        };
      });
    },
    start() {
      return currentDateString("YYYY-MM-DD HH:mm");
    },
    end() {
      return currentDateString("YYYY-MM-DD HH:mm");
    },
  },
  methods: {
    hasAppointmentsDate(date) {
      return this.events.some(
        (event) => dateToStringWithoutTime(event.startAt) === date
      );
    },
    redirectToAppointments({ date }) {
      this.$router.push({
        name: "listAppointmentsTherapist",
        query: { selectedDate: date },
      });
    },
  },
};
</script>

<style scoped></style>
