import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export const stringToDate = (string, format) => {
  dayjs.extend(customParseFormat);
  return dayjs(string, format).toDate();
};

export const dateToString = (date, format) => {
  return dayjs(date).format(format);
};

export const currentDateString = (format) => {
  return dayjs().format(format);
};

export const dateToStringWithoutTime = (date) => {
  return dayjs(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD");
};
